@import "../../styles/variables";

.header {
  margin-top: 42px;
  margin-bottom: 10px;
  font-size: 24px;
}

.account_warning {
  display: flex;
  align-items: center;

  height: 73px;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 24px;
  margin-bottom: 3px;
  background-color: $gray-6;

  font-weight: 400;
  font-size: 18px;
  line-height: 20px;

  color: #000000;

  &::before {
    content: "";
    display: block;
    width: 32px;
    height: 32px;
    background-image: url("./icons/shield.svg");
    background-size: contain;
    background-repeat: no-repeat;
    margin-right: 24px;
  }
}

.section_header {
  font-size: 16px;
  line-height: 17px;
  display: flex;
  align-items: center;

  color: #000000;
  padding-bottom: 3px;
  margin-top: 23px;

  border-bottom: 1px solid #cccccc;
}
.eyeIcon{
  position: absolute;
  z-index: 1;
  right: 5px;
  top:55px;
  opacity: 0.5;
  cursor: pointer;
}
.permissionContainer{
  margin-right: auto;
  // width: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 0 !important;
  overflow: hidden;
  min-width: 500px;
}
.permissionHeader{
  display: flex;
  border-bottom: 1px solid $primary-low;
}
.permissionHeader:last-child{
  border-bottom: 0 !important;
}
.permissionHeaderItem{
  // width: 200px;
  flex: 1;
  border-right: 1px solid $primary-low;

  padding: 20px 15px;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
}
.permissionHeaderItem:last-child{
  border-right: 0 !important;
}
.permissionHeaderItem>p{
  font-weight: 500;
  margin: 0;
  font-size: 16px;
}
.checkbox{
  &:checked{
    background-color: $status-default;
    border-color:  $status-default;
  }
}


