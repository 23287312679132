@import "../../styles/variables";

.date_created {
  border-radius: 8px;
  min-width: 240px;
}

.search_wrapper{
  gap: 10px;
  justify-content: flex-start;
}


